import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import Button from "../../Components/UI/Button";
import Modal from "../../Components/UI/Modal";
import { useUser } from "../../context/UserContext";
import { toast_error_settings } from "../../utils/constants";
import { mobileAxios } from "../../utils/mobileAxios";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";

type Props = {};

function descendingComparator(a: any, b: any, orderBy: any) {
    if (
        orderBy === "id" ||
        orderBy === "statys" ||
        orderBy === "code" ||
        orderBy === "available"
    ) {
        return b[orderBy].toLowerCase().localeCompare(a[orderBy].toLowerCase());
    } else if (orderBy.includes("date")) {
        return dayjs(b[orderBy]).isAfter(dayjs(a[orderBy])) ? -1 : 1;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

function getComparator(order: any, orderBy: any) {
    return order === "desc"
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: "code",
        label: "Code",
    },
    {
        id: "available",
        label: "Available",
    },
    {
        id: "created_date",
        label: "Created Date",
    },
    {
        id: "action",
        label: "",
    },
];

function EnhancedTableHead(props: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className="font-semibold text-base"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const WifiCodes = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState<{
        defaultValue: string;
        id: string;
        type: "add" | "update";
    } | null>(null);

    const [rows, setRows] = useState([]);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("created_date");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const { userState } = useUser();
    const params = useParams();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }
        await mobileAxios
            .post("/express_proxy", {
                method: "POST",
                endpoint: `/wifi/${params.branch_id}`,
                adminId: userState?.admin_id_mobile,

                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                // params: {
                //     method: "get",
                // },
            })

            .then((res) => {
                const rowsData = res.data?.[1]?.map((item: any) => ({
                    id: item._id,
                    created_date: item.created_date,
                    code: item.code,
                    available: item.available,
                }));

                setRows(rowsData);

                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const formData = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});
        setIsLoading(true);

        try {
            const res = await mobileAxios.post("/express_proxy", {
                method: modalOpen?.type === "add" ? "POST" : "PATCH",
                endpoint: `/wifi/${
                    modalOpen?.type === "update" ? modalOpen?.id : ""
                }`,
                branch: formData.branch,
                code: formData.code,
                adminId: userState?.admin_id_mobile,

                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                // params: {
                //     method: "get",
                // },
            });

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setModalOpen(null);
    };

    if (data === "loading") {
        return <Loader />;
    }

    async function onDelete(wifiId: String) {
        try {
            const res = await mobileAxios.post("/express_proxy", {
                method: "DELETE",
                endpoint: `/wifi/${wifiId}`,

                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                // params: {
                //     method: "get",
                // },
            });

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
    }

    const deleteWificode = async (code_id: string) => {
        setIsLoading(true);

        try {
            const res = await mobileAxios.post("/express_proxy", {
                method: "DELETE",
                endpoint: `/wifi/${code_id}`,
                adminId: userState?.admin_id_mobile,

                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                // params: {
                //     method: "get",
                // },
            });

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
    };

    return (
        <div>
            {isLoading && <Loader withBg={true} />}

            <div className="flex items-center gap-8">
                <p className="font-bold text-lg mb-6 inline-block">
                    {data[0].name}
                </p>
                <button
                    onClick={() =>
                        setModalOpen({
                            defaultValue: "",
                            id: data[0]._id,
                            type: "add",
                        })
                    }
                    className="py-2 px-4 mb-6 text-xs flex items-center justify-center border"
                >
                    + Add Code
                </button>
            </div>

            {rows.length > 0 && (
                <Box sx={{ width: "100%" }}>
                    <Paper sx={{ width: "100%", mb: 2 }}>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={"medium"}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    onSelectAllClick={() => {}}
                                />
                                <TableBody>
                                    {rows
                                        .sort(getComparator(order, orderBy))
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row: any, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.id}
                                                >
                                                    <TableCell>
                                                        {row.code}
                                                    </TableCell>

                                                    <TableCell>
                                                        {row.available}
                                                    </TableCell>

                                                    <TableCell>
                                                        {dayjs(
                                                            row.created_date
                                                        ).format(
                                                            "ddd DD MMM YYYY hh:mm A"
                                                        )}
                                                    </TableCell>

                                                    <TableCell>
                                                        <Button
                                                            className="ml-auto text-xs border-red-500 bg-red-500"
                                                            type="button"
                                                            onClick={() => {
                                                                deleteWificode(
                                                                    row.id
                                                                );
                                                            }}
                                                        >
                                                            DELETE
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            )}

            <Modal
                size="sm"
                isOpen={modalOpen !== null}
                toggleModal={() => setModalOpen(null)}
            >
                <form className="mt-6" onSubmit={(e) => handleSubmit(e)}>
                    <div className="input-row">
                        <input
                            name="code"
                            placeholder="Code"
                            defaultValue={modalOpen?.defaultValue}
                        />
                        <input
                            type="hidden"
                            name="branch"
                            defaultValue={data[0]._id}
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => setModalOpen(null)}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default WifiCodes;

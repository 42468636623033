import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Dropzone from "../../../Components/Dropzone";
import EditWrapper from "../../../Components/EditWrapper";
import Loader from "../../../Components/Loader/Loader";
import Button from "../../../Components/UI/Button";
import Modal from "../../../Components/UI/Modal";
import { useUser } from "../../../context/UserContext";
import { MEDIA_URL, toast_error_settings } from "../../../utils/constants";
import { mobileAxios } from "../../../utils/mobileAxios";

type Props = {};

const AdminMobileEvents = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [uploadedImageGallery, setUploadedImageGallery] =
        useState<String>("");

    const [uploadedImg, setUploadedImg] = useState<string | undefined>(
        undefined
    );

    const [bookingsModel, setBookingsModel] = useState<any[] | null>(null);

    const [addRoomModal, setAddRoomModal] = useState<{
        title?: string;
        image?: string;
        id?: string;
        exp_date?: string;
        description?: string;
        total_seats?: string | number;
        type: "add" | "update";
        gallery?: any[];
    } | null>(null);
    const [deleteModal, setDeleteModal] = useState<string | null>(null);

    const { userState } = useUser();

    const params = useParams();

    const openRoomModal = (type: "add" | "update") => {
        setAddRoomModal({ type: type });
    };

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        mobileAxios
            .post("/express_proxy", {
                endpoint: `/events/branch/${params.branch_id}`,
                method: "get",
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
            })

            .then((res) => {
                if (res.data.length === 0) {
                    throw new Error("Nothing returned");
                }
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    if (data === "loading") {
        return <Loader />;
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await mobileAxios.post("/express_proxy", {
                method: addRoomModal?.type === "add" ? "POST" : "PATCH",

                endpoint:
                    addRoomModal?.type === "add"
                        ? "/events"
                        : `/events/${addRoomModal?.id}`,
                adminId: userState?.admin_id_mobile,
                branch: params.branch_id,
                title: formData.title,
                image: uploadedImg,
                description: formData.description,
                exp_date: formData.exp_date,
                total_seats: formData.total_seats,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                // params: {
                //     method: "get",
                // },
            });

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setAddRoomModal(null);
    };

    const handleGalleryDelete = async (image_id: string) => {
        const gallery: string[] = [];
        addRoomModal?.gallery?.forEach((img) => {
            if (img?.["_id"] !== image_id) {
                gallery.push(img);
            }
        });

        setIsLoading(true);

        try {
            const res = await mobileAxios.post("/express_proxy", {
                method: "DELETE",
                endpoint: `/events/gallery/${image_id}`,
                adminId: userState?.admin_id_mobile,

                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                // params: {
                //     method: "get",
                // },
            });

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            setAddRoomModal((p) => ({
                ...(addRoomModal as any),
                gallery: gallery,
            }));
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
    };

    const handleRoomDelete = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const res = await mobileAxios.post("/express_proxy", {
                method: "DELETE",
                endpoint: `/events/${deleteModal}`,
                adminId: userState?.admin_id_mobile,

                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                // params: {
                //     method: "get",
                // },
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setDeleteModal(null);
    };

    const openBookings = async (eventId: string) => {
        try {
            const res = await mobileAxios.post("/express_proxy", {
                method: "POST",
                endpoint: `/events/booked/${eventId}`,
                adminId: userState?.admin_id_mobile,

                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                // params: {
                //     method: "get",
                // },
            });

            setBookingsModel(res.data);
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    return (
        <>
            {isLoading && <Loader withBg={true} />}
            <div>
                <button
                    onClick={() => {
                        setUploadedImg("");
                        openRoomModal("add");
                    }}
                    className="mt-8 h-full min-h-[50px] w-full flex items-center justify-center border"
                >
                    + Add Event
                </button>
                <br />
                <br />
                {Array.isArray(data) &&
                    data?.map((room: any) => (
                        <div id={room?.[0].title} key={room?.[0]._id}>
                            <EditWrapper
                                ref={undefined}
                                toggleModal={() => {
                                    setUploadedImg(room?.[0].image);
                                    setAddRoomModal({
                                        type: "update",
                                        id: room?.[0]._id,
                                        title: room?.[0].title,
                                        image: room?.[0].image,
                                        exp_date: dayjs(
                                            room?.[0].exp_date
                                        ).toString(),
                                        description: room?.[0].description,
                                        total_seats: room?.[0].total_seats,
                                        gallery: room?.[1],
                                    });
                                }}
                                onDelete={() => setDeleteModal(room?.[0]._id)}
                                className="!w-full"
                            >
                                <div
                                    className="flex gap-12 w-full"
                                    key={room?.[0]._id}
                                >
                                    <div className="flex-1">
                                        <h2
                                            className="font-semibold text-xl mb-2"
                                            dangerouslySetInnerHTML={{
                                                __html: room?.[0].title || "",
                                            }}
                                        />

                                        <div className="grid grid-cols-3 gap-4">
                                            <img
                                                src={
                                                    MEDIA_URL + room?.[0].image
                                                }
                                                alt=""
                                                className={` h-full w-full object-contain`}
                                            />
                                        </div>

                                        {room?.[0].description && (
                                            <div className="mt-2">
                                                <h2
                                                    className="font-bold text-lg mb-2 whitespace-nowrap"
                                                    dangerouslySetInnerHTML={{
                                                        __html: "Description",
                                                    }}
                                                />
                                                <p
                                                    className="whitespace-pre-wrap"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            room?.[0]
                                                                .description ||
                                                            "",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex-1 mt-8 grid grid-cols-2 gap-4">
                                        {room?.[0].exp_date && (
                                            <div>
                                                <h2
                                                    className="font-bold text-lg mb-2 whitespace-nowrap"
                                                    dangerouslySetInnerHTML={{
                                                        __html: "Exp date",
                                                    }}
                                                />
                                                <div className="flex gap-2">
                                                    <p
                                                        className="whitespace-pre-wrap"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                dayjs(
                                                                    room?.[0]
                                                                        .exp_date
                                                                )
                                                                    .format(
                                                                        "ddd DD MMM YYYY"
                                                                    )
                                                                    .toString() ||
                                                                "",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            <h2
                                                className="font-bold text-lg mb-2 whitespace-nowrap"
                                                dangerouslySetInnerHTML={{
                                                    __html: "Seats",
                                                }}
                                            />
                                            <p className="whitespace-pre-wrap">
                                                {room?.[0].booked_seats} /{" "}
                                                {room?.[0].total_seats}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </EditWrapper>
                            <Button
                                className="mt-4 px-4 text-sm !py-2"
                                onClick={() => openBookings(room?.[0]?.["_id"])}
                            >
                                View Bookings
                            </Button>
                            <hr className="my-6" />
                        </div>
                    ))}
            </div>
            <Modal
                size="3xl"
                isOpen={bookingsModel !== null}
                toggleModal={() => setBookingsModel(null)}
            >
                <div>
                    <h2 className="font-semibold text-xl mb-2">Bookings:</h2>
                    {bookingsModel?.length === 0 &&
                        "No bookings for this event yet"}
                    {bookingsModel?.map((item) => (
                        <>
                            <div className="grid grid-cols-2">
                                <div>
                                    <b>User: </b>
                                    <span> {item.user_id?.name} </span>
                                    <span> {item.user_id?.email} </span>
                                </div>
                                <div>
                                    <b>Booked at: </b>
                                    <span>
                                        {" "}
                                        {dayjs(
                                            item.created_date
                                        ).toString()}{" "}
                                    </span>
                                </div>
                            </div>
                            <hr className="my-4" />
                        </>
                    ))}
                </div>
            </Modal>

            <Modal
                size="2xl"
                isOpen={addRoomModal !== null}
                toggleModal={() => setAddRoomModal(null)}
            >
                <form
                    className="mt-6"
                    onSubmit={(e) => {
                        handleSubmit(e);
                    }}
                >
                    <div className="input-row">
                        <input
                            name="title"
                            type="text"
                            placeholder="Event title *"
                            defaultValue={addRoomModal?.title || ""}
                        />
                    </div>

                    <div key={Math.random()}>
                        <Dropzone
                            defaultValue={uploadedImg || ""}
                            handleIconUpload={(src: string) => {
                                setUploadedImg(src);
                            }}
                            previewClass="w-full"
                        />
                    </div>

                    <br />

                    <div className="input-row flex gap-4">
                        <input
                            name="total_seats"
                            type="number"
                            step="any"
                            placeholder="Total seats"
                            defaultValue={addRoomModal?.total_seats || ""}
                        />
                    </div>

                    <div className="input-row  gap-4">
                        <textarea
                            name="description"
                            placeholder="Description"
                            defaultValue={addRoomModal?.description || ""}
                            rows={4}
                        />

                        <br />
                        <br />
                        <input
                            type="date"
                            name="exp_date"
                            placeholder="Exp date (MM-DD-YYYY)"
                            defaultValue={dayjs(addRoomModal?.exp_date)
                                .format("YYYY-MM-DD")
                                .toString()}
                        />
                    </div>

                    <div>
                        <h3 className="mt-2 font-semibold text-lg">Gallery:</h3>
                        <div className="grid grid-cols-4 gap-4">
                            {addRoomModal?.gallery?.map((img, index) => (
                                <div key={img + index} className="relative">
                                    <img src={MEDIA_URL + img.image} alt="" />
                                    <button
                                        type="button"
                                        className="absolute top-0 right-0"
                                        onClick={() => {
                                            handleGalleryDelete(img["_id"]);
                                        }}
                                    >
                                        <MdDelete className="text-red-500" />
                                    </button>
                                </div>
                            ))}
                        </div>
                        {addRoomModal?.type === "add" ? (
                            "Add the event then edit it to add gallery"
                        ) : (
                            <div>
                                <Dropzone
                                    defaultValue={uploadedImageGallery || ""}
                                    handleIconUpload={async (src: string) => {
                                        setIsLoading(true);
                                        const res = await mobileAxios.post(
                                            "/express_proxy",
                                            {
                                                method: "POST",
                                                endpoint: `/events/gallery`,
                                                adminId:
                                                    userState?.admin_id_mobile,

                                                eventId: addRoomModal?.id,
                                                images: [src],
                                                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                                                // params: {
                                                //     method: "get",
                                                // },
                                            }
                                        );

                                        setIsLoading(false);
                                        fetchData();

                                        if (addRoomModal?.gallery) {
                                            setAddRoomModal((p: any) => ({
                                                ...p,
                                                gallery: [
                                                    ...p?.gallery,
                                                    res.data,
                                                ],
                                            }));
                                        } else {
                                            console.log(res.data);
                                            setAddRoomModal((p: any) => ({
                                                ...p,
                                                gallery: [res.data],
                                            }));
                                        }
                                        // setUploadedImageGallery(src)
                                    }}
                                    previewClass="w-full"
                                />
                            </div>
                        )}
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => setAddRoomModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                isOpen={deleteModal !== null}
                toggleModal={() => setDeleteModal(null)}
            >
                <form className="mt-6" onSubmit={handleRoomDelete}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete this room?
                    </h4>
                    <div className="flex justify-center space-x-4">
                        <Button
                            className=" mt-8"
                            type="button"
                            onClick={() => setDeleteModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className=" mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default AdminMobileEvents;

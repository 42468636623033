import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Dropzone from "../../Components/Dropzone";
import EditWrapper from "../../Components/EditWrapper";
import Loader from "../../Components/Loader/Loader";
import Button from "../../Components/UI/Button";
import Modal from "../../Components/UI/Modal";
import { useUser } from "../../context/UserContext";
import { MEDIA_URL, toast_error_settings } from "../../utils/constants";
import { mobileAxios } from "../../utils/mobileAxios";
import { Axiosget } from "../../utils/axiosget";
import Select from "react-select";
import axios from "axios";
import { json } from "stream/consumers";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
type Props = {};

const MobileAnnouncements = (props: Props) => {
    const [data, setData] = useState<any>("loading");

    const [externalLink, setExternalLink] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [uploadedImage, setUploadedImage] = useState("");
    // const [announcementDetail, setAnnouncementDetail] = useState<any>({});
    const [redirectionType, setredirectionType] = useState<
        "internal" | "external" | null
    >(null);
    const [internalType, setInternalType] = useState<
        null | "merch" | "menu" | "space" | "events"
    >(null);
    const [selectedInternalId, setSelectedInternalId] = useState<null | string>(
        null
    );

    const [dropdownValues, setDropdownValues] = useState<{
        menu: { label: any; value: any }[];
        merch: { label: any; value: any }[];
        space: { label: any; value: any }[];
        events: { label: any; value: any }[];
    }>({ menu: [], merch: [], space: [], events: [] });

    type Row = {
        id: string;
        name: string;
        price: number;
        type: string;
        created_date: string;
    };

    const [modalOpen, setModalOpen] = useState<{
        defaultValue: string;
        id: string;
        type: "add" | "update";
    } | null>(null);

    const [rows, setRows] = useState<Row[]>([]);
    console.log(rows, "rows");

    const { userState } = useUser();
    const params = useParams();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }
        mobileAxios("/express_proxy", {
            method: "POST",
            params: {
                endpoint: `/announcement/branch/${params.branch_id}`,
                adminId: userState?.admin_id_mobile,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                method: "get",
            },
        })
            .then((res) => {
                const rowsData = res.data?.map((item: any) => ({
                    id: item._id,
                    image: item.image,
                    link: item.link ? item.link : "",
                    order: item?.order,
                }));

                rowsData.sort((a: any, b: any) => a.order - b.order);
                setRows(rowsData);

                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };
    useEffect(() => {
        if (!isLoading) {
            saveOrderHandler();
        }
    }, [rows]);
    const saveOrderHandler = async () => {
        setIsLoading(true);

        let theNewOrderOfList: Array<object> = [];
        rows?.map((row, index) => {
            theNewOrderOfList.push({
                id: row?.id,
                // name: row?.name,
                order: index + 1,
            });
        });
        console.log(theNewOrderOfList, " theNewOrderOfList");
        // setIsLoading(false);
        // return;
        try {
            setIsLoading(true); // Set loading state before request

            const res = await mobileAxios.post("/express_proxy", {
                endpoint: "/announcement/update-order",
                method: "post",
                adminId: userState?.admin_id_mobile.toString(),
                announcement: theNewOrderOfList,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
            });

            // After successful response
            setIsLoading(false);
            toast.success("The New Order Has Been Saved");
            fetchData();
        } catch (error: any) {
            // Handle error
            setIsLoading(false);
            toast.error(
                `An error occurred: ${
                    error.response?.data?.message || error.message
                }`, // Better error message handling
                toast_error_settings
            );
        }
    };

    const fetchDropdownData = async () => {
        try {
            if (!isLoading) {
                setIsLoading(true);
            }

            const res = await mobileAxios.post("/express_proxy", {
                endpoint: `/announcement/dropdown/branch-${params.branch_id}`,
                    adminId: userState?.admin_id_mobile,
                    auth: `Bearer ${userState?.admin_login_token_mobile}`,
                    method: "get",
                params: {
                    
                },
            });

            let menuLeb = [];
            let menulbjson = [];
            let menu = [];

            try {
                const menuLeb = await Axiosget.post(
                    params.branch_id == "6332a9b7b87b0f5f682934e7"
                        ? "lebanon/menu.json"
                        : "canada/menu.json"
                );
                menulbjson = JSON.parse(menuLeb.data);
                menu = menulbjson[0].map((item: any) => {
                    return {
                        label: `${item[0]?.["name"]}`,
                        value: item[0]?.["_id"],
                    };
                });
            } catch (err: any) {}

            let spaces: { label: any; value: any }[] = [];
            let events: { label: any; value: any }[] = [];

            try {
                spaces = res.data[0]?.spaces?.map((s: any) => ({
                    value: s?.["_id"],
                    label: s?.["title"],
                }));

                events = res.data[1]?.events?.map((s: any) => ({
                    value: s?.["_id"],
                    label: s?.["title"],
                }));
            } catch (error) {}

            setDropdownValues({
                menu: menu,
                merch: [],
                events: events,
                space: spaces,
            });
        } catch (error: any) {
            console.error(error);
            toast.error(error.message);
        }
    };

    useEffect(() => {
        fetchData();
        fetchDropdownData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    const linkRef = useRef<any>("");

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return;

        const items = [...rows];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setRows(items);

        // if (!result.destination) return;

        // const updatedRows = [...rows];
        // const [reorderedItem] = updatedRows.splice(result.source.index, 1);
        // updatedRows.splice(result.destination.index, 0, reorderedItem);

        // setRows(updatedRows);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            // `/announcement/${
            //         modalOpen?.type === "update" ? modalOpen?.id : ""
            //     }`
            // console.log("modalOpen?.type", modalOpen?.type);

            const res = await mobileAxios.post("/express_proxy", {
                endpoint:
                    modalOpen?.type === "update"
                        ? `/announcement/${modalOpen?.id}`
                        : "/announcement",
                method: modalOpen?.type === "add" ? "POST" : "PATCH",
                branch: params.branch_id,
                image: uploadedImage,
                adminId: userState?.admin_id_mobile,
                link_type: redirectionType,
                link: externalLink ?? null,
                internal_link: selectedInternalId ?? null,
                internal_type: internalType ?? null,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
            });

            setExternalLink("");

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setUploadedImage("");
        setIsLoading(false);
        setModalOpen(null);
    };

    const getTheDetailOfAnnouncement = async () => {
        setIsLoading(true);
        try {
            const res = await mobileAxios("/express_proxy", {
                method: "POST",
                params: {
                    endpoint: `/announcement/${modalOpen?.id}`,
                    auth: `Bearer ${userState?.admin_login_token_mobile}`,
                    method: "get",
                },
            });

            // console.log(res?.data, "res?.data");

            // Pre-fill the form with the announcement details
            setUploadedImage(res?.data?.image); // Set the uploaded image
            setredirectionType(res?.data?.link_type); // Set redirection type
            setInternalType(
                res?.data?.internal_type ? res?.data?.internal_type : null
            ); // Set internal type (menu, merch, space, or events)
            setExternalLink(res?.data?.link); // Set
            setSelectedInternalId(res?.data?.internal_link); // Set the selected ID based on internal type
            setIsLoading(false);
        } catch (error: any) {
            toast.error(
                `An error occurred: ${error.message}`,
                toast_error_settings
            );
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (modalOpen) {
            if (modalOpen?.type === "update") {
                getTheDetailOfAnnouncement();
            } else if (modalOpen?.type === "add") {
                // Reset all the form fields for adding new announcement
                setUploadedImage("");
                setredirectionType(null);
                setInternalType(null);
                setSelectedInternalId(null);
            }
        }
    }, [modalOpen]);

    // useEffect(() => {}, [uploadedImage]);
    if (data === "loading") {
        return <Loader />;
    }

    const deleteAnnouncement = async (code_id: string) => {
        setIsLoading(true);

        try {
            const res = await mobileAxios.post("/express_proxy", {
                endpoint: `/announcement/${code_id}`,
                method: "DELETE",
                adminId: userState?.admin_id_mobile,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
            });

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
    };

    return (
        <div>
            {isLoading && <Loader withBg={true} />}

            <div className="flex items-center gap-8">
                <p className="font-bold text-lg mb-6 inline-block">
                    {data[0]?.name}
                </p>
                <button
                    onClick={() => {
                        setModalOpen({
                            defaultValue: "",
                            // data[0]?._id
                            id: data[0]?._id,
                            type: "add",
                        });
                        setUploadedImage(""); // Clear the uploaded image
                        setredirectionType(null); // Reset the redirection type (internal or external)
                        setInternalType(null); // Reset the internal type (menu, merch, etc.)
                        setSelectedInternalId(null); // Clear any selected internal item

                        // if (linkRef?.current) {
                        //     linkRef.current.value = ""; // Clear the external link input
                        // }
                    }}
                    className="py-2 px-4 mb-6 text-xs flex items-center justify-center border"
                >
                    + Add Announcement
                </button>
            </div>

            {rows?.length > 0 && (
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="rows">
                        {(provided) => (
                            <div
                                className="w-full grid md:grid-cols-4"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {rows.map((r: any, index) => (
                                    <Draggable
                                        key={r.id}
                                        draggableId={r.id}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div>
                                                    <EditWrapper
                                                        onDelete={() =>
                                                            deleteAnnouncement(
                                                                r?.id
                                                            )
                                                        }
                                                        toggleModal={() => {
                                                            setModalOpen({
                                                                defaultValue:
                                                                    r?.image,
                                                                id: r?.id,
                                                                type: "update",
                                                            });

                                                            if (
                                                                linkRef.current
                                                            ) {
                                                                linkRef.current.value =
                                                                    r?.link
                                                                        ? r?.link
                                                                        : ""; // Assign the value only if the ref is not null
                                                            } // Assign the link or an empty string if no link exists
                                                        }}
                                                        className="!w-full"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div>
                                                            <img
                                                                src={
                                                                    MEDIA_URL +
                                                                    r.image
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                    </EditWrapper>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}

            <Modal
                size="lg"
                isOpen={modalOpen !== null}
                toggleModal={() => {
                    // Reset the states when closing the modal
                    setredirectionType(null);
                    setInternalType(null);
                    setUploadedImage("");
                    setModalOpen(null);
                }}
            >
                <form className="mt-6" onSubmit={(e) => handleSubmit(e)}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={uploadedImage?.toString()} // Pre-fill image in Dropzone
                            handleIconUpload={(src: string) =>
                                setUploadedImage(src)
                            }
                            previewClass="w-full"
                        />

                        <div>
                            <p>Redirection type</p>
                            <div className="flex items-center">
                                <div className="flex items-center gap-2">
                                    <input
                                        onChange={() =>
                                            setredirectionType("internal")
                                        }
                                        type="radio"
                                        id="internal"
                                        name="link_type"
                                        value="internal"
                                        required
                                        checked={redirectionType === "internal"}
                                    />
                                    <label htmlFor="internal">Internal</label>
                                </div>
                                <div className="flex items-center gap-2 ml-6">
                                    <input
                                        onChange={() =>
                                            setredirectionType("external")
                                        }
                                        type="radio"
                                        id="external"
                                        name="link_type"
                                        value="external"
                                        required
                                        checked={redirectionType === "external"}
                                    />
                                    <label htmlFor="external">External</label>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            {redirectionType === "internal" && (
                                <div>
                                    {/* Menu */}
                                    <div className="my-4">
                                        <div className="flex items-center gap-2 mb-2">
                                            <input
                                                onChange={() =>
                                                    setInternalType("menu")
                                                }
                                                className="!w-[20px]"
                                                type="radio"
                                                name="internal_type"
                                                value="menu"
                                                id="menu"
                                                required
                                                checked={
                                                    internalType === "menu"
                                                }
                                            />
                                            <label htmlFor="menu">Menu</label>
                                        </div>
                                        {internalType === "menu" && (
                                            <Select
                                                onChange={(e) =>
                                                    setSelectedInternalId(
                                                        e?.value
                                                    )
                                                }
                                                value={dropdownValues?.menu.filter(
                                                    (item) =>
                                                        item.value ===
                                                        selectedInternalId
                                                )}
                                                options={dropdownValues?.menu.map(
                                                    (item) => ({
                                                        value: item.value,
                                                        label: item.label,
                                                    })
                                                )}
                                            />
                                        )}
                                    </div>

                                    {/* Merch */}
                                    <div className="my-4">
                                        <div className="flex items-center gap-2 mb-2">
                                            <input
                                                onChange={() =>
                                                    setInternalType("merch")
                                                }
                                                className="!w-[20px]"
                                                type="radio"
                                                name="internal_type"
                                                value="merch"
                                                id="merch"
                                                required
                                                checked={
                                                    internalType === "merch"
                                                }
                                            />
                                            <label htmlFor="merch">Merch</label>
                                        </div>
                                        {internalType === "merch" && (
                                            <Select
                                                onChange={(e) =>
                                                    setSelectedInternalId(
                                                        e?.value
                                                    )
                                                }
                                                defaultValue={dropdownValues?.merch.find(
                                                    (item) =>
                                                        item.value ===
                                                        selectedInternalId
                                                )}
                                                options={dropdownValues?.merch.map(
                                                    (item) => ({
                                                        value: item.value,
                                                        label: item.label,
                                                    })
                                                )}
                                            />
                                        )}
                                    </div>

                                    {/* Space */}
                                    <div className="my-4">
                                        <div className="flex items-center gap-2 mb-2">
                                            <input
                                                onChange={() =>
                                                    setInternalType("space")
                                                }
                                                className="!w-[20px]"
                                                type="radio"
                                                name="internal_type"
                                                value="space"
                                                id="space"
                                                required
                                                checked={
                                                    internalType === "space"
                                                }
                                            />
                                            <label htmlFor="space">Space</label>
                                        </div>
                                        {internalType === "space" && (
                                            <Select
                                                onChange={(e) =>
                                                    setSelectedInternalId(
                                                        e?.value
                                                    )
                                                }
                                                defaultValue={dropdownValues?.space.find(
                                                    (item) =>
                                                        item.value ===
                                                        selectedInternalId
                                                )}
                                                options={dropdownValues?.space.map(
                                                    (item) => ({
                                                        value: item.value,
                                                        label: item.label,
                                                    })
                                                )}
                                            />
                                        )}
                                    </div>

                                    {/* Events */}
                                    <div className="my-4">
                                        <div className="flex items-center gap-2 mb-2">
                                            <input
                                                onChange={() =>
                                                    setInternalType("events")
                                                }
                                                className="!w-[20px]"
                                                type="radio"
                                                name="internal_type"
                                                value="events"
                                                id="events"
                                                required
                                                checked={
                                                    internalType === "events"
                                                }
                                            />
                                            <label htmlFor="events">
                                                Events
                                            </label>
                                        </div>
                                        {internalType === "events" && (
                                            <Select
                                                onChange={(e) =>
                                                    setSelectedInternalId(
                                                        e?.value
                                                    )
                                                }
                                                defaultValue={dropdownValues?.events.find(
                                                    (item) =>
                                                        item.value ===
                                                        selectedInternalId
                                                )}
                                                options={dropdownValues?.events.map(
                                                    (item) => ({
                                                        value: item.value,
                                                        label: item.label,
                                                    })
                                                )}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                            {redirectionType === "external" && (
                                <div>
                                    <input
                                        ref={linkRef}
                                        type="text"
                                        required
                                        name="link"
                                        placeholder="Redirection Url"
                                        value={externalLink}
                                        onChange={(e) =>
                                            setExternalLink(e.target.value)
                                        }
                                        defaultValue={externalLink || ""}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => {
                                setredirectionType(null);
                                setInternalType(null);
                                setUploadedImage("");
                                setModalOpen(null);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default MobileAnnouncements;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TitleWithText from "../../Components/EditComponents/TitleWithText";
import Loader from "../../Components/Loader/Loader";
import { useUser } from "../../context/UserContext";
import { mobileAxios } from "../../utils/mobileAxios";
import { toast_error_settings } from "../../utils/constants";
import Modal from "../../Components/UI/Modal";
import Button from "../../Components/UI/Button";
import { NavLink } from "react-router-dom";

type Props = {};

const AdminLocations = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [countryModal, setCountryModal] = useState(false);
    const [branchModal, setBrandModal] = useState<string | null>(null);
    const [deleteModal, setDeleteModal] = useState<{
        type: "country" | "branch";
        id: string;
    } | null>(null);

    const toggleCountryModal = () => {
        setCountryModal((p) => !p);
    };

    const { userState } = useUser();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        mobileAxios("/express_proxy", {
            method: "post",
            params: {
                endpoint: "/countries",
                adminId: userState?.admin_id_mobile,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                method: "get",
            },
        })
            .then((res) => {
                if (res.data.length === 0) {
                    throw new Error("Nothing returned");
                }
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    const handleSubmit = async (
        submittedData: {
            component_title?: string;
            component_description?: string;
        },
        sectionKey: string,
        updating_component_id: string | number
    ) => {
        setIsLoading(true);

        try {
            const res = await mobileAxios.post("/express_proxy", {
                endpoint: `${
                    sectionKey === "branch" ? "/branches" : "/countries"
                }/${updating_component_id}`,
                method: "PATCH",
                adminId: userState?.admin_id_mobile,
                country: submittedData.component_title,
                name: submittedData.component_description,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
            });

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setDeleteModal(null);
    };

    const handleAddCountrySubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await mobileAxios.post("/express_proxy", {
                endpoint: `/countries`,
                method: "POST",
                adminId: userState?.admin_id_mobile,
                country: data.country,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
            });

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        toggleCountryModal();
    };

    const handleAddBranchSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await mobileAxios.post("/express_proxy", {
                endpoint: `/branches`,
                method: "POST",
                adminId: userState?.admin_id_mobile,
                name: data.branch,
                country: branchModal,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
            });

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setBrandModal(null);
    };

    const handleDelete = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const res = await mobileAxios.post("/express_proxy", {
                endpoint: `${
                    deleteModal?.type === "branch" ? "/branches" : "/countries"
                }/${deleteModal?.id}`,
                method: "DELETE",
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
            });

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setDeleteModal(null);
    };

    return (
        <div>
            {isLoading && <Loader withBg={true} />}
            <div className="flex gap-8 items-center mb-4">
                <h2 className="text-4xl font-bold text-darkblue ">LOCATIONS</h2>

                <button
                    onClick={toggleCountryModal}
                    className="w-fit flex items-center justify-center border px-6 py-3"
                >
                    Add new country
                </button>
            </div>

            {data?.[0]?.map((country: any, index: any) => (
                <>
                    <div
                        key={index}
                        className="flex items-center gap-6 mt-6 bg-darkblue py-5 px-4"
                    >
                        <TitleWithText
                            key={country._id}
                            component_id={country._id}
                            title={country.country}
                            titleClassName={"text-2xl text-white mb-0"}
                            text={""}
                            // onDelete={() =>
                            //     setDeleteModal({
                            //         type: "country",
                            //         id: country._id,
                            //     })
                            // }
                            onSubmit={handleSubmit}
                            sectionKey="country"
                        />

                        <NavLink
                            className=" py-2 px-4 text-sm flex items-center justify-center border text-white"
                            to={`/mobile-app/merchandise/${country._id}`}
                        >
                            Merchandise
                        </NavLink>

                        <NavLink
                            className=" py-2 px-4 text-sm flex items-center justify-center border text-white"
                            to={`/mobile-app/menus/${country._id}`}
                        >
                            Menus
                        </NavLink>

                        <NavLink
                            className=" py-2 px-4 text-sm flex items-center justify-center border text-white"
                            to={`/mobile-app/categories/${country._id}`}
                            state={{ countryId: country._id }}
                        >
                            Categories
                        </NavLink>

                        <NavLink
                            className=" py-2 px-4 text-sm flex items-center justify-center border text-white"
                            to={`/mobile-app/addons/${country._id}`}
                        >
                            Addons
                        </NavLink>

                        <NavLink
                            className=" py-2 px-4 text-sm flex items-center justify-center border text-white"
                            to={`/mobile-app/notifications/${country._id}`}
                        >
                            Notifications
                        </NavLink>
                    </div>

                    <div className="border border-darkblue ">
                        {data?.[1]
                            ?.filter((b: any) => b.country === country._id)
                            ?.map((cBranch: any) => (
                                <div
                                    className="grid grid-cols-5 gap-4 border-b border-gray-300 mt-3 pb-3 px-3"
                                    key={cBranch._id}
                                >
                                    <div className="border-r border-gray-300 font-semibold">
                                        <TitleWithText
                                            component_id={cBranch._id}
                                            title={""}
                                            text={cBranch.name}
                                            // onDelete={() =>
                                            //     setDeleteModal({
                                            //         type: "branch",
                                            //         id: cBranch._id,
                                            //     })
                                            // }
                                            onSubmit={handleSubmit}
                                            sectionKey="branch"
                                        />
                                    </div>
                                    <div>
                                        <NavLink
                                            to={`/mobile-app/announcement/${cBranch._id}`}
                                            className="h-full w-full flex items-center justify-center border-r border-gray-300"
                                        >
                                            Announcements
                                        </NavLink>
                                    </div>
                                    <div>
                                        <NavLink
                                            to={`/mobile-app/wifi-codes/${cBranch._id}`}
                                            className="h-full w-full flex items-center justify-center border-r border-gray-300"
                                        >
                                            Wifi codes
                                        </NavLink>
                                    </div>
                                    <div>
                                        <NavLink
                                            to={`/mobile-app/spaces/${cBranch._id}`}
                                            className="h-full w-full flex items-center justify-center border-r border-gray-300"
                                        >
                                            Spaces
                                        </NavLink>
                                    </div>

                                    <div>
                                        <NavLink
                                            to={`/mobile-app/events/${cBranch._id}`}
                                            className="h-full w-full flex items-center justify-center "
                                        >
                                            Events
                                        </NavLink>
                                    </div>

                                    <div className="h-full w-full flex items-center justify-center border-r border-gray-300"></div>
                                </div>
                            ))}
                    </div>

                    <button
                        onClick={() => setBrandModal(country._id)}
                        className="font-medium  py-3 px-4  flex items-center justify-center border mx-auto mt-4"
                    >
                        + Add branch
                    </button>

                    <div className="h-[2px] bg-darkblue bg-opacity-25 w-full my-10"></div>
                </>
            ))}

            <Modal
                size="2xl"
                isOpen={countryModal}
                toggleModal={toggleCountryModal}
            >
                <form className="mt-6" onSubmit={handleAddCountrySubmit}>
                    <div className="input-row">
                        <input
                            name="country"
                            type="text"
                            placeholder="Country Name *"
                            required
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleCountryModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                size="2xl"
                isOpen={branchModal !== null}
                toggleModal={() => setBrandModal(null)}
            >
                <form className="mt-6" onSubmit={handleAddBranchSubmit}>
                    <div className="input-row">
                        <input
                            name="branch"
                            type="text"
                            placeholder="Branch Name *"
                            required
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => setBrandModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                isOpen={deleteModal !== null}
                toggleModal={() => setDeleteModal(null)}
            >
                <form className="mt-6" onSubmit={handleDelete}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete this {deleteModal?.type}
                        ?
                    </h4>

                    {deleteModal?.type === "country" && (
                        <p className="text-center text-gray-600 mt-2">
                            Deleting a country will also delete all its related
                            branches.
                        </p>
                    )}
                    <div className="flex space-x-4">
                        <Button
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => setDeleteModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ml-auto mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default AdminLocations;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import EditWrapper from "../../Components/EditWrapper";
import Loader from "../../Components/Loader/Loader";
import Button from "../../Components/UI/Button";
import Modal from "../../Components/UI/Modal";
import { useUser } from "../../context/UserContext";
import { MEDIA_URL, toast_error_settings } from "../../utils/constants";
import { mobileAxios } from "../../utils/mobileAxios";

import Select from "react-select";

import { Button as MUIButton } from "@mui/material";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import dayjs from "dayjs";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

type Props = {};
//  decending Comperator
function descendingComparator(a: any, b: any, orderBy: any) {
    if (
        orderBy === "id" ||
        orderBy === "color" ||
        orderBy === "type" ||
        orderBy === "name"
    ) {
        return b[orderBy]
            ?.toLowerCase()
            ?.localeCompare(a[orderBy]?.toLowerCase());
    } else if (orderBy.includes("date")) {
        return dayjs(b[orderBy]).isAfter(dayjs(a[orderBy])) ? -1 : 1;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// get Comperator

function getComparator(order: any, orderBy: any) {
    return order === "desc"
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

////////////////////////////////////////////////////////////////////////////////////////////////////

//  head cell
const headCells = [
    {
        id: "name",
        label: "Name",
    },
    {
        id: "price",
        label: "Price",
    },
    {
        id: "type",
        label: "Type",
    },
    {
        id: "created_date",
        label: "Created Date",
    },
    {
        id: "action",
        label: "",
    },
];

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Enhanced Table Component

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]),
    orderBy: PropTypes.string,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHead(props: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className="font-semibold text-base"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

///////////////////////////////////////////////////////////////////////////

// Manage addons

const ManageAddons = (props: Props) => {
    // all useState for manage addons
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [addonsTypes, setaddonsTypes] = useState<string[]>([]);
    const [selectedTypes, setSelectedTypes] = useState<
        { value: string; label: string }[]
    >([]);

    const [addonsModal, setaddonsModal] = useState<string | null | "open">(
        null
    );
    const [deleteModal, setDeleteModal] = useState<string | null>(null);
    const [editValues, setEditValues] = useState<{
        title: string;
        price: string | number;
        type: string;
    }>({ title: "", price: "", type: "" });
    /////////////////////////////////////////////////////////////////////////////////////////////

    // toggle Modal
    const toggleAddonModal = () => {
        setSelectedTypes([]);
        setEditValues({ title: "", price: "", type: "" });
        if (addonsModal === null) {
            setaddonsModal("open");
        } else {
            setaddonsModal(null);
        }
    };
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    type Row = {
        id: string;
        name: string;
        price: number;
        type: string;
        created_date: string;
    };
    const [rows, setRows] = useState<Row[]>([]);
    const [order, setOrder] = useState("");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    // Sort Function
    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        // setisResorted(true);
    };

    // change page Function
    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };
    // change rows per page Function
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 25));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const { userState } = useUser(); // user state
    const params = useParams(); // params
    ////////////////////////////////////////////////////////////////////////////////////////////////
    // console.log("rows ", rows);

    // fetch data func
    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }
        mobileAxios("/express_proxy", {
            method: "POST",
            params: {
                endpoint: `/addOns/country-${params.country_id}`,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                method: "get",
            },
        })
            .then((res) => {
                const rowsData = res.data?.map((item: any) => {
                    return {
                        id: item._id,
                        name: item.name || item.title,
                        price: item.price,
                        type: item.type,
                        created_date: item.created_date,
                        order: item?.order,
                    };
                });
                rowsData.sort((a: any, b: any) => a.order - b.order);
                setRows(rowsData);

                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });

        mobileAxios("/express_proxy", {
            method: "POST",
            params: {
                endpoint: "/categories",
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                method: "get",
            },
        })
            .then((res) => {
                const tmpData = [
                    ...res.data
                        ?.filter((item: any) => item.type === "menu")
                        ?.map((item: any) => item.name.toLowerCase()),
                ];
                setaddonsTypes(tmpData);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    //////////////////////////////////////////////////////////////////////////////
    // fetching data on first render
    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);
    useEffect(() => {
        if (!isLoading) {
            saveOrderHandler();
        }
    }, [rows]);

    if (data === "loading") {
        return <Loader />;
    }
    ///////////////////////////////////////////////////////////////////////////////////////////
    // delete item func
    const handleItemDelete = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const res = await mobileAxios.post("/express_proxy", {
                endpoint: `/addons/${deleteModal}`,
                method: "DELETE",
                adminId: userState?.admin_id_mobile,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
            });

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setDeleteModal(null);
    };
    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // add new addons funcs
    const handleAddNewAddon = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await mobileAxios.post("/express_proxy", {
                method: addonsModal !== "open" ? "PATCH" : "POST",
                endpoint: `/addOns/${
                    addonsModal !== "open" ? addonsModal : ""
                }`,
                adminId: userState?.admin_id_mobile,
                title: formData.title,
                price: formData.price,
                type: selectedTypes.map((item) => item.value).join(","),
                country: params.country_id,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                // params: {
                //     method: "get",
                // },
            });

            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        toggleAddonModal();
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////

    // end Drag func
    const handleDragEnd = (result: any) => {
        if (!result.destination) return;

        const updatedRows = [...rows];
        const [reorderedItem] = updatedRows.splice(result.source.index, 1);
        updatedRows.splice(result.destination.index, 0, reorderedItem);

        // console.log("updatedRows", updatedRows);

        setRows(updatedRows);
    };

    /////////////////////////////////////////////////////////////

    const saveOrderHandler = async () => {
        // console.log("Rows Inside The saveOrderHandler", rows);

        setIsLoading(true);
        let theNewOrderOfList: Array<object> = [];
        rows?.map((row, index) => {
            theNewOrderOfList.push({
                id: row?.id,
                // name: row?.name,
                order: index + 1,
            });
        });
        console.log("theNewOrderOfList ", theNewOrderOfList);

        // return;
        try {
            setIsLoading(true); // Set loading state before request

            const res = await mobileAxios.post("/express_proxy", {
                method: "POST",
                endpoint: "/addOns/update-order",
                adminId: userState?.admin_id_mobile.toString(),
                addOns: theNewOrderOfList,
                auth: `Bearer ${userState?.admin_login_token_mobile}`,
                // params: {
                //     method: "get",
                // },
            });
            // After successful response
            setIsLoading(false);
            toast.success("The New Order Has Been Saved");
            fetchData();
        } catch (error: any) {
            // Handle error
            setIsLoading(false);
            console.log(error, "res");

            toast.error(
                `An error occurred: ${
                    error.response?.data?.message || error.message
                }`, // Better error message handling
                toast_error_settings
            );
        }
        // POST /addOns/update-order
    };

    /////////////////////////////////////////////////////////////
    // the Component
    return (
        <>
            {isLoading && <Loader withBg={true} />}
            <div className="h-fit mb-8 flex gap-8 items-center">
                <h2 className="font-bold text-2xl">Addons :</h2>

                <button
                    onClick={toggleAddonModal}
                    className="px-8 py-3 flex items-center justify-center border"
                >
                    + Add Addons
                </button>
                {/* <button
                    className="w-fit p-[10px] cursor-pointer bg-blue-900 text-white mb-[1rem] rounded"
                    onClick={saveOrderHandler}
                >
                    Save The Order
                </button> */}
            </div>

            {rows.length > 0 && (
                <Box sx={{ width: "100%" }}>
                    <Paper sx={{ width: "100%", mb: 2 }}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="table">
                                {(provided) => (
                                    <TableContainer>
                                        <Table
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            sx={{ minWidth: 750 }}
                                            aria-labelledby="tableTitle"
                                            size={"medium"}
                                        >
                                            {/* <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={
                                                    handleRequestSort
                                                }
                                                rowCount={rows.length}
                                                onSelectAllClick={() => {}}
                                            /> */}
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Price</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>
                                                        Created Date
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows
                                                    // .sort(
                                                    //     getComparator(
                                                    //         order,
                                                    //         orderBy
                                                    //     )
                                                    // )
                                                    .slice(
                                                        page * rowsPerPage,
                                                        page * rowsPerPage +
                                                            rowsPerPage
                                                    )
                                                    .map((row: any, index) => {
                                                        return (
                                                            <Draggable
                                                                key={row.id}
                                                                draggableId={
                                                                    row.id
                                                                }
                                                                index={index}
                                                            >
                                                                {(provided) => (
                                                                    <TableRow
                                                                        hover
                                                                        tabIndex={
                                                                            -1
                                                                        }
                                                                        key={
                                                                            row.id
                                                                        }
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <TableCell>
                                                                            {
                                                                                row.name
                                                                            }
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            ${" "}
                                                                            {
                                                                                row.price
                                                                            }
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            {
                                                                                row.type
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {dayjs(
                                                                                row.created_date
                                                                            ).format(
                                                                                "ddd DD MMM YYYY hh:mm A"
                                                                            )}
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <MUIButton
                                                                                variant="outlined"
                                                                                onClick={() => {
                                                                                    setaddonsModal(
                                                                                        row.id
                                                                                    );
                                                                                    setEditValues(
                                                                                        {
                                                                                            title:
                                                                                                row.name ||
                                                                                                row.title,
                                                                                            price: row.price,
                                                                                            type: row.type,
                                                                                        }
                                                                                    );
                                                                                    setSelectedTypes(
                                                                                        row.type
                                                                                            .split(
                                                                                                ","
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    item: any
                                                                                                ) => ({
                                                                                                    value: item,
                                                                                                    label: item,
                                                                                                })
                                                                                            )
                                                                                    );
                                                                                }}
                                                                            >
                                                                                EDIT
                                                                                ITEM
                                                                            </MUIButton>
                                                                            <br />

                                                                            <MUIButton
                                                                                className="!block !mt-2"
                                                                                variant="outlined"
                                                                                color="error"
                                                                                onClick={() =>
                                                                                    setDeleteModal(
                                                                                        row.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                DELETE
                                                                                ITEM
                                                                            </MUIButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                {emptyRows > 0 && (
                                                    <TableRow
                                                        style={{
                                                            height:
                                                                53 * emptyRows,
                                                        }}
                                                    >
                                                        <TableCell
                                                            colSpan={6}
                                                        />
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Droppable>
                        </DragDropContext>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            )}

            <Modal
                size="3xl"
                isOpen={addonsModal !== null}
                toggleModal={toggleAddonModal}
            >
                <form className="mt-6" onSubmit={handleAddNewAddon}>
                    <div className="input-wrap items-center flex gap-4">
                        <p>Name:</p>

                        <input
                            className="flex-1 "
                            name={`title`}
                            onBlur={() => {}}
                            type="text"
                            placeholder="Name *"
                            required
                            defaultValue={editValues?.title || ""}
                        />
                    </div>

                    <br />

                    <div className="input-wrap space-x-4">
                        <label>Price:</label>

                        <div className="input-wrap flex-1 inline-flex items-center gap-2">
                            <span className="text-xl">$</span>
                            <input
                                className="flex-1 "
                                name={`price`}
                                type="number"
                                step="any"
                                placeholder="Price *"
                                defaultValue={editValues?.price || ""}
                                required
                            />
                        </div>
                    </div>

                    <br />

                    <div className="input-wrap items-center flex gap-4 input-row">
                        <p>Type:</p>

                        <Select
                            value={selectedTypes}
                            onChange={(e) => {
                                setSelectedTypes(e as any);
                            }}
                            name="type"
                            options={addonsTypes.map((item) => ({
                                value: item,
                                label: item,
                            }))}
                            isMulti
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={toggleAddonModal}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                isOpen={deleteModal !== null}
                toggleModal={() => setDeleteModal(null)}
            >
                <form className="mt-6" onSubmit={handleItemDelete}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete this Item?
                    </h4>
                    <div className="flex justify-center space-x-4">
                        <Button
                            className=" mt-8"
                            type="button"
                            onClick={() => setDeleteModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className=" mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default ManageAddons;

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import { useUser } from "../../../context/UserContext";
import { mobileAxios } from "../../../utils/mobileAxios";

type Props = {};

const AdminMobileMenu = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { userState } = useUser();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        mobileAxios
            .post("/express_proxy", {
                method: "GET",
                endpoint: `/countries`,
                params: {
                    adminId: userState?.admin_id_mobile,
                    auth: `Bearer ${userState?.admin_login_token_mobile}`,
                    method: "get",
                },
            })
            .then((res) => {
                if (res.data.length === 0) {
                    throw new Error("Nothing returned");
                }
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    return (
        <div>
            {isLoading && <Loader withBg={true} />}
            {data?.[0]?.map((country: any) => (
                <>
                    <div className="flex gap-4">
                        <p className="font-bold text-lg mb-2 inline-block">
                            {country.country}
                        </p>

                        <NavLink
                            className=" py-2 px-4 text-sm flex items-center justify-center border"
                            to={`${country._id}`}
                        >
                            View menu
                        </NavLink>
                    </div>

                    <hr className="my-8" />
                </>
            ))}
        </div>
    );
};

export default AdminMobileMenu;
